<template>
<div class="source">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="source-nr">
    <div class="train-tab">
      <p class="active">资源管理</p>
    </div>
    <div class="top-tip mb20">
      提示：资源管理以教师为维度列出的文件夹；方便管理和查看每个教师上传的课程资源文件；可对老师的资源进行下载、预览、删除操作。
    </div>
    <div v-if="sourceUserList.length">
      <div class="user-list" v-if="!curUser.user_id">
        <div class="user-list-item" v-for="(item,index) in sourceUserList" :key="index" @click="goInfo(item)">
          <div>
            <div class="user-list-item-name">
              <span>{{ item.user.nickname }}</span> 老师文件
            </div>
            <div>最新上传日期：{{ item.created_at }}</div>
          </div>
        </div>
      </div>
      <div v-else class="source-list">
        <div class="source-list-tit" @click="goBack">
          <img src="../../assets/images/new_icon/back.png" width="10" height="17" />
          <p>{{ curUser.user.nickname }}</p>
        </div>
        <Source :userId="curUser.user_id"></Source>
      </div>
    </div>
    <div v-else class="no-data">
      <img src="../../assets/images/new_icon/nodata.png" width="158" height="82" />
      <p>还没有教师上传文件，上传后即可查看</p>
    </div>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import Source from './components/source'
export default {
  name: "sourceData",
  data(){
    return{
      // columns:[
      //   {
      //     title: '文件名称',
      //     key:'name',
      //     render: (h, params) => {
      //       return h('div',{
      //         style:{
      //           'display':'flex',
      //           'justifyContent':'flex-start',
      //           'alignCenter':'center',
      //           'cursor':'pointer'
      //         },
      //         on:{
      //           click:()=>{
      //             this.goSourceInfo(params.row)
      //           }
      //         }
      //       },[
      //           h('img',{
      //             attrs:{
      //               src:params.row.is_dir == '1' ? require('../../assets/images/new_icon/wenjianjia.png') : require('../../assets/images/new_icon/wenjian.png'),
      //               width: params.row.is_dir == '1' ? '22' : '16'
      //             },
      //             style:{
      //               'marginRight':'5px',
      //             }
      //           }),
      //           h('span',params.row.name)
      //       ]);
      //     }
      //   },
      //   {
      //     title: '大小',
      //     key:'size',
      //     render: (h, params) => {
      //       return h('span', params.row.size ? params.row.size : '-' );
      //     }
      //   },
      //   {
      //     title: '格式',
      //     key:'file_extension',
      //     render: (h, params) => {
      //       return h('span', params.row.is_dir == '1' ? '文件夹' : params.row.file_extension );
      //     }
      //   },
      //   {
      //     title: '上传日期',
      //     key:'id',
      //     render: (h, para) => {
      //       return h('span', util.timeFormatter(
      //               new Date(+para.row.created_at * 1000),
      //               'yyyy-MM-dd hh:mm'
      //           )
      //       );
      //     }
      //   },
      //   {
      //     title: '操作',
      //     render: (h, params) => {
      //       return h('div', [
      //         params.row.is_dir == '1' ? h('Button', {
      //           props: {
      //             type: 'primary',
      //             size: 'small'
      //           },
      //           style: {
      //             marginRight: '5px',
      //             marginTop: '5px'
      //           },
      //           on: {
      //             click: () => {
      //               this.goSourceInfo(params.row)
      //             }
      //           }
      //         }, '查看详情') : '',
      //         params.row.is_dir != '1' && h('Button', {
      //           props: {
      //             type: 'primary',
      //             size: 'small'
      //           },
      //           style: {
      //             marginRight: '5px',
      //             marginTop: '5px'
      //           },
      //           on: {
      //             click: () => {
      //               this.downLoad(params.row.path)
      //             }
      //           }
      //         }, '下载'),
      //
      //         params.row.is_dir != '1' && h('Button', {
      //           props: {
      //             type: 'primary',
      //             size: 'small'
      //           },
      //           style: {
      //             marginRight: '5px',
      //             marginTop: '5px'
      //           },
      //           on: {
      //             click: () => {
      //               this.check(params.row.path);
      //             }
      //           }
      //         }, '预览'),
      //         params.row.is_dir != '1' &&  h('Button', {
      //           props: {
      //             type: 'error',
      //             size: 'small'
      //           },
      //           style: {
      //             marginRight: '5px',
      //             marginTop: '5px'
      //           },
      //           on: {
      //             click: () => {
      //               this.curListData = params.row;
      //               this.deleteSource();
      //             }
      //           }
      //         }, '删除'),
      //       ]);
      //     }
      //   },
      // ],
      // dataList:[],
      // total:0,
      // page:1,
      // pageSize:10,
      sourceUserList:[],
      // loading:false,
      curUser:{},
      // deleModalStatus:false,
      // modalLoading:false,
      // curData:{},
      // tabList:[],
      // curListData:{},
    }
  },
  components:{
    Source
  },
  created(){
    this.getSourceUserList();
  },
  methods:{
    getSourceUserList(){
      this.api.course.teachingSourceUserList().then((res)=>{
        res.list.forEach((item)=>{
          item.created_at = util.timeFormatter(
              new Date(+item.created_at * 1000),
              'yyyy-MM-dd hh:mm'
          )
        })
        this.sourceUserList = res.list;
        console.log(this.sourceUserList,'this.sourceUserList')
      })
    },
    goInfo(data){
      this.curUser = data;
    },

    goBack(){
      this.curUser = {};
    },

  }
}
</script>

<style scoped lang="scss">
.source{
  margin: 20px;
  .source-nr{
    padding: 20px;
    background-color: #FFFFFF;
    font-size: 14px;

    .train-tab{
      margin-bottom: 20px;

      border-bottom: 1px solid #EEEEEE;
      font-size: 16px;
      color: #666666;
      display: flex;
      justify-content: flex-start;
      >p{
        padding-bottom: 10px;
      }
      .active{
        font-weight: bold;
        color: #4A79FF;
        border-bottom: 4px solid #5782FF;
      }
    }

    .user-list{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .user-list-item{
        cursor: pointer;
        width: 25%;

        >div{
          margin-right: 20px;
          padding: 30px 20px;
          background-color: #F6F7FA;
        }
        .user-list-item-name{
          margin-bottom: 10px;
          font-weight: bold;
          >span{
            font-size: 16px;
          }
        }
      }
    }
    .source-list{
      .source-list-tit{
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        >img{
          margin-right: 6px;
        }
      }
      //.source-list-tab{
      //  margin-bottom: 10px;
      //  display: flex;
      //  justify-content: flex-start;
      //  align-items: center;
      //  .source-list-tab-item{
      //    margin-right: 10px;
      //    display: flex;
      //    justify-content: flex-start;
      //    align-items: center;
      //    cursor: pointer;
      //    >p{
      //      margin: 0 10px;
      //    }
      //  }
      //}
      //.alignC{
      //  display: flex;
      //  justify-content: flex-start;
      //  align-items: center;
      //}
      //.page{
      //  margin-top: 20px;
      //  text-align: right;
      //}
    }
  }
}
.no-data{
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  >p{
    margin-top: 35px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }
}
</style>
